/**
 * WARNING!!!
 * 
 * The SIGN_UP_STEPS is used in the mobile application as well. It is duplicated there...
 * If you are changing It - make sure to change the mobile application code as well or at list 
 * to check if the changes are not required there.
 */
export const SIGN_UP_STEPS = {
	// HOME: 'HOME',
	VERIFY_IDENTIFIER: 'VERIFY_IDENTIFIER',
	EDIT_CODE: 'EDIT_CODE',
	EXTEND_USER_DETAILS: 'EXTEND_USER_DETAILS',
	SIGNED_OUT: 'SIGNED_OUT',
	DELETED: 'DELETED',
}

export const AUTHENTICATION_TYPE = {
	VERIFICATION_CODE: 'verificationCode',
	GUEST: 'guest',
}

export const ECOMMERCE_USER = 'eCommerceUser'
